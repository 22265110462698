import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import axiosInstance from "api/axios";

interface Member {
  id: string;
  name: string;
  email: string;
  password: string;
  createdAt: string;
  timezone: string;
  clientId: string;
  departmentIds: string[];
}

interface MemberState {
  members: Member[];
  loading: boolean;
  error: string | null;
}

const initialState: MemberState = {
  members: [],
  loading: false,
  error: null,
};

// Async thunk to handle fetching all members
export const fetchMembers = createAsyncThunk(
  "members/fetchAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/members");
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Fetch failed");
    }
  }
);

// Async thunk to handle fetching members by client ID
export const fetchMembersByClientId = createAsyncThunk(
  "members/fetchByClientId",
  async (clientId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/members/client/${clientId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Fetch failed");
    }
  }
);

// Async thunk to handle creating a new member
export const createMember = createAsyncThunk(
  "members/create",
  async (payload: Omit<Member, "id" | "createdAt">, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/members", payload);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Creation failed"
      );
    }
  }
);

// Async thunk to handle updating a member
export const updateMember = createAsyncThunk(
  "members/update",
  async (payload: Partial<Member> & { id: string }, { rejectWithValue }) => {
    try {
      const { id, ...updateData } = payload;
      const response = await axiosInstance.patch(`/members/${id}`, updateData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Update failed");
    }
  }
);

// Async thunk to handle deleting a member
export const deleteMember = createAsyncThunk(
  "members/delete",
  async (id: string, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/members/${id}`);
      return id;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Deletion failed"
      );
    }
  }
);

// Async thunk to handle deleting member by client and member
export const deleteMemberByClientAndMember = createAsyncThunk(
  "members/deleteByClientAndMember",
  async (
    payload: { clientId: string; memberId: string },
    { rejectWithValue }
  ) => {
    try {
      await axiosInstance.delete(`/members/by-client-and-member`, {
        data: payload,
      });
      return payload;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Deletion failed"
      );
    }
  }
);

const memberSlice = createSlice({
  name: "members",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMembers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchMembers.fulfilled,
        (state, action: PayloadAction<Member[]>) => {
          state.members = action.payload;
          state.loading = false;
        }
      )
      .addCase(fetchMembers.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchMembersByClientId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchMembersByClientId.fulfilled,
        (state, action: PayloadAction<Member[]>) => {
          state.members = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        fetchMembersByClientId.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(createMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createMember.fulfilled,
        (state, action: PayloadAction<Member>) => {
          state.members.push(action.payload);
          state.loading = false;
        }
      )
      .addCase(createMember.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateMember.fulfilled,
        (state, action: PayloadAction<Member>) => {
          const index = state.members.findIndex(
            (member) => member.id === action.payload.id
          );
          if (index !== -1) {
            state.members[index] = action.payload;
          }
          state.loading = false;
        }
      )
      .addCase(updateMember.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteMember.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.members = state.members.filter(
            (member) => member.id !== action.payload
          );
          state.loading = false;
        }
      )
      .addCase(deleteMember.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteMemberByClientAndMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteMemberByClientAndMember.fulfilled,
        (
          state,
          action: PayloadAction<{ clientId: string; memberId: string }>
        ) => {
          state.members = state.members.filter(
            (member) =>
              !(
                member.clientId === action.payload.clientId &&
                member.id === action.payload.memberId
              )
          );
          state.loading = false;
        }
      )
      .addCase(
        deleteMemberByClientAndMember.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default memberSlice.reducer;
