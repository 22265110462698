// src/store/sessionDetailsSlice.js
import { RootState } from "store/store";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "api/axios";

// src/types/SessionDetail.ts

export interface CreateTeamValueInterface {
  rating: number;
  clientId: string;
  consultantId: string;
  value: string;
}

export interface TeamValueDetailsState {
  teamValueDetails: CreateTeamValueInterface | null;
  loading: boolean;
  error: string | null;
}

export const createTeamValueRecord = createAsyncThunk<
  any, // Adjust this type to your actual response type
  CreateTeamValueInterface,
  { state: RootState; rejectValue: string }
>(
  "sessionDetails/createSessionDetail",
  async (
    { rating, value, clientId, consultantId }: CreateTeamValueInterface,
    { getState, rejectWithValue }
  ) => {
    const { token } = getState().auth;

    console.log(clientId);
    console.log(consultantId);
    try {
      const response = await axiosInstance.post(
        `/team-values/create/${consultantId}`,
        { rating, clientId, value },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Failed to send request");
    }
  }
);

const initialState: TeamValueDetailsState = {
  teamValueDetails: null,
  loading: false,
  error: null,
};

const teamValueDetails = createSlice({
  name: "teamValueDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createTeamValueRecord.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTeamValueRecord.fulfilled, (state, action) => {
        state.loading = false;
        state.teamValueDetails = action.payload;
      })
      .addCase(createTeamValueRecord.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default teamValueDetails.reducer;
