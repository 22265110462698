// src/store/documentSlice.ts
import { RootState } from "store/store";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "api/axios";

export enum DocumentType {
  EMPLOYEE_HANDBOOK = "Employee Handbook",
  DISCIPLINARY_POLICY = "Disciplinary Policy",
  HEALTH_SAFETY_POLICY = "Health & Safety Policy",
  EMPLOYMENT_CONTRACTS = "Employment Contracts",
  EQUAL_OPPORTUNITIES = "Equal Opportunities Policy",
  MATERNITY_POLICY = "Maternity Policy",
  CODE_OF_CONDUCT = "Code of Conduct",
  PAYROLL_POLICY = "Payroll Policy",
  BENEFITS = "Benefits Information",
  OTHER = "Other",
}
// src/types/Document.ts
export interface CreateDocumentInterface {
  title: string;
  description?: string;
  fileUrl: string;
  accessLevel: "HR Only" | "All Employees";
  publish: "Draft" | "Public";
  type?: DocumentType;
}

export interface DocumentInterface extends CreateDocumentInterface {
  id: string;
  createdAt: string;
  updatedAt: string;
}

export interface DocumentState {
  documentDetails: DocumentInterface | null;
  documents: DocumentInterface[];
  loading: boolean;
  error: string | null;
}
export const createDocument = createAsyncThunk<
  DocumentInterface,
  CreateDocumentInterface,
  { state: RootState; rejectValue: string }
>(
  "document/createDocument",
  async (
    { title, description, fileUrl, accessLevel, publish, type },
    { getState, rejectWithValue }
  ) => {
    const { token, role } = getState().auth;

    try {
      const endpoint =
        role === "client" || role === "manager"
          ? `/documents/createForClient`
          : `/documents/createForMember`;

      const response = await axiosInstance.post(
        endpoint,
        { title, description, fileUrl, accessLevel, publish, type },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("🚀 ~ response:", response);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Failed to create document");
    }
  }
);

// Fetch documents for a specific client with role-based access
export const fetchDocumentsForMember = createAsyncThunk<
  DocumentInterface[], // Return type
  void, // No argument is needed
  { state: RootState; rejectValue: string }
>(
  "document/fetchDocumentsForMember",
  async (_, { getState, rejectWithValue }) => {
    const { token } = getState().auth;

    try {
      const response = await axiosInstance.get(`/documents/member`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("🚀 ~ response:", response);

      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Failed to fetch documents");
    }
  }
);

export const fetchDocumentsForClient = createAsyncThunk<
  DocumentInterface[], // Return type
  void, // No argument is needed
  { state: RootState; rejectValue: string }
>(
  "document/fetchDocumentsForClient",
  async (_, { getState, rejectWithValue }) => {
    const { token } = getState().auth;

    try {
      const response = await axiosInstance.get(`/documents/client`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("🚀 ~ response:", response);

      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Failed to fetch documents");
    }
  }
);
// Update an existing document
export const updateDocument = createAsyncThunk<
  DocumentInterface,
  { id: string; updateData: Partial<CreateDocumentInterface> },
  { state: RootState; rejectValue: string }
>(
  "document/updateDocument",
  async ({ id, updateData }, { getState, rejectWithValue }) => {
    console.log("🚀 ~ updateData:", updateData);
    console.log("🚀 ~ id:", id);
    const { token } = getState().auth;

    try {
      const response = await axiosInstance.put(
        `/documents/update/${id}`,
        updateData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("🚀 ~ response:", response);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue("Failed to update document");
    }
  }
);

// Delete a document by ID
export const deleteDocument = createAsyncThunk<
  string, // ID of the deleted document
  string, // Document ID to be passed
  { state: RootState; rejectValue: string }
>("document/deleteDocument", async (id, { getState, rejectWithValue }) => {
  const { token } = getState().auth; // Ensure token is retrieved correctly

  try {
    await axiosInstance.delete(`/documents/delete/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return id; // Return the deleted document ID for optimistic updates
  } catch (error: any) {
    if (error.response && error.response.data) {
      return rejectWithValue(error.response.data);
    }
    return rejectWithValue("Failed to delete document");
  }
});

const initialState: DocumentState = {
  documentDetails: null,
  documents: [],
  loading: false,
  error: null,
};

const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Create document
      .addCase(createDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.documents.push(action.payload); // Add new document to list
      })
      .addCase(createDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Fetch documents
      .addCase(fetchDocumentsForMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDocumentsForMember.fulfilled, (state, action) => {
        state.loading = false;
        state.documents = action.payload; // Populate documents list
      })
      .addCase(fetchDocumentsForMember.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Fetch documents
      .addCase(fetchDocumentsForClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDocumentsForClient.fulfilled, (state, action) => {
        state.loading = false;
        state.documents = action.payload; // Populate documents list
      })
      .addCase(fetchDocumentsForClient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Update document
      .addCase(updateDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDocument.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.documents.findIndex(
          (doc) => doc.id === action.payload.id
        );
        if (index !== -1) {
          state.documents[index] = action.payload; // Update document in list
        }
      })
      .addCase(updateDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Delete document
      .addCase(deleteDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.documents = state.documents.filter(
          (doc) => doc.id !== action.payload
        ); // Remove deleted document
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default documentSlice.reducer;
