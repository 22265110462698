import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "store/store"; // Adjust the import path as necessary
import store from "store/store";
// import { clearToken } from "store/slices/authSlice"; // Ensure this action exists in your authSlice
interface PublicRouteProps {
  children: JSX.Element;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const { token, role } = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    // If there's a token but no role, we need to clear the token
    if (token && !role) {
      console.log("Token exists but no role, clearing token.");
      // dispatch(clearToken()); // Dispatch action to clear the token
      store.dispatch({ type: "auth/clearToken" });
      // Clear token and role from localStorage
      localStorage.removeItem("token");
      localStorage.removeItem("role");
    }
  }, [token, role, dispatch]);

  console.log("🚀 ~ role:", role);
  console.log("🚀 ~ token:", token);

  if (token && role) {
    let redirectPath = "/";
    switch (role) {
      case "sysadmin":
        redirectPath = "/admin/clients";
        break;
      case "consultant":
        redirectPath = "/consultant/clients";
        break;
      case "client":
        redirectPath = "/client/dashboard";
        break;
      case "member":
        redirectPath = "/member/dashboard";
        break;
      default:
        break;
    }

    const loginPaths = [
      "/admin/login",
      "/consultant/login",
      "/client/login",
      "/member/login",
    ];

    console.log(redirectPath);
    if (loginPaths.includes(location.pathname)) {
      return <Navigate to={redirectPath} />;
    }

    return children;
  }

  return children;
};

export default PublicRoute;

// import React from "react";
// import { Navigate, useLocation } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { RootState } from "store/store";

// interface PublicRouteProps {
//   children: JSX.Element;
// }

// const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
//   const { token, role } = useSelector((state: RootState) => state.auth);
//   console.log("🚀 ~ role:", role);
//   const location = useLocation();
//   console.log("🚀 ~ token:", token);

//   if (token) {
//     let redirectPath = "/";
//     switch (role) {
//       case "sysadmin":
//         redirectPath = "/admin/clients";
//         break;
//       case "consultant":
//         redirectPath = "/consultant/clients";
//         break;
//       case "client":
//         redirectPath = "/client/dashboard";
//         break;
//       case "member":
//         redirectPath = "/member/updates";
//         break;
//       default:
//         break;
//     }

//     const loginPaths = [
//       "/admin/login",
//       "/consultant/login",
//       "/client/login",
//       "/member/login",
//     ];
//     console.log(redirectPath);
//     if (loginPaths.includes(location.pathname)) {
//       return <Navigate to={redirectPath} />;
//     }

//     return children;
//   }

//   return children;
// };

// export default PublicRoute;
