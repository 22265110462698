import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import consultantReducer from "./slices/consultantSlice";
import consultantTypeReducer from "./slices/consultantTypeSlice";
import clientReducer from "./slices/clientSlice";
import clientConsultantReducer from "./slices/clientConsultantSlice";
import memberReducer from "./slices/memberSlice";
// Persistence
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import updateReducer from "./slices/updateSlice";
import clientUpdateReducer from "./slices/clientUpdate";
import memberUpdateReducer from "./slices/memberUpdate";
import departmentReducer from "./slices/departmentSlice";
import goalReducer from "./slices/goalSlice";
import weeklyScorecardReducer from "./slices/weeklyScorecardSlice";
import rockReducer from "./slices/rockSlice";
import taskReducer from "./slices/taskSlice";
import detailsReducer from "./slices/detailsSlice";
import issuesReducer from "./slices/issueSlice";
import sessionReducer from "./slices/sessionDetailsSlice";
import fileReducer from "./slices/fileSlice";
import notificationReducer from "./slices/notificationSlice";
import reportsReducer from "./slices/reportSlice";
import notesReducer from "./slices/noteSlice";
import teamValueDetailsReducer from "./slices/teamValueSlice";
import documentsReducer from "./slices/documentSlice";

// Combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  consultants: consultantReducer,
  consultantTypes: consultantTypeReducer,
  clients: clientReducer,
  clientConsultants: clientConsultantReducer,
  members: memberReducer,
  updates: updateReducer,
  clientUpdates: clientUpdateReducer,
  memberUpdates: memberUpdateReducer,
  departments: departmentReducer,
  goals: goalReducer,
  weeklyScorecards: weeklyScorecardReducer,
  rocks: rockReducer,
  tasks: taskReducer,
  details: detailsReducer,
  issues: issuesReducer,
  sessionDetails: sessionReducer,
  clientFiles: fileReducer,
  notifications: notificationReducer,
  reports: reportsReducer,
  notes: notesReducer,
  teamValueDetails: teamValueDetailsReducer,
  documents: documentsReducer,
});

// Persistence configuration
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

export default store;
