// store/slices/weeklyScorecardSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "api/axios";
import { RootState } from "store/store";

// Define the new payload type
interface SaveWeeklyScorecardPayload {
  id: string;
  newValue: string;
}

interface Member {
  id: string;
  name: string;
}

interface Goal {
  name: string;
  measurable: string;
  member: Member | null;
}

interface Scorecard {
  goal: Goal;
  value: string;
}

interface WeeklyScorecard {
  id: string | null;
  week: Date;
  scorecards: Scorecard[];
  isDaily: Boolean | null;
}

interface WeeklyScorecardState {
  weeklyScorecards: WeeklyScorecard[];
  loading: boolean;
  error: string | null;
}

const initialState: WeeklyScorecardState = {
  weeklyScorecards: [],
  loading: false,
  error: null,
};

// Async thunk for saving weekly scorecards
export const saveWeeklyScorecards = createAsyncThunk<
  WeeklyScorecard[],
  WeeklyScorecard[],
  { rejectValue: string; state: RootState }
>(
  "weeklyScorecards/saveWeeklyScorecards",
  async (weeklyScorecards, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    console.log(weeklyScorecards);
    try {
      const response = await axiosInstance.post(
        "/weekly-scorecards/save",
        weeklyScorecards,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to save weekly scorecards"
      );
    }
  }
);

// export const saveWeeklyScorecards = createAsyncThunk<
//   SaveWeeklyScorecardPayload[], // What the action resolves to (returned data)
//   SaveWeeklyScorecardPayload[], // The payload type you are sending to the backend
//   { rejectValue: string; state: RootState }
// >(
//   "weeklyScorecards/SaveWeeklyScorecards",
//   async (weeklyScorecardsPayload, { getState, rejectWithValue }) => {
//     const { token } = getState().auth;
//     console.log(weeklyScorecardsPayload); // Ensure payload is correct

//     try {
//       const response = await axiosInstance.post(
//         "/weekly-scorecards/save",
//         weeklyScorecardsPayload, // Send the payload to the API
//         {
//           headers: { Authorization: `Bearer ${token}` },
//         }
//       );
//       console.log(response);

//       return response.data; // Return the response data (updated scorecards)
//     } catch (error: any) {
//       console.log(error);
//       return rejectWithValue(
//         error.response?.data?.message || "Failed to save weekly scorecards"
//       );
//     }
//   }
// );
export const memberSaveWeeklyScorecards = createAsyncThunk<
  SaveWeeklyScorecardPayload[], // What the action resolves to (returned data)
  SaveWeeklyScorecardPayload[], // The payload type you are sending to the backend
  { rejectValue: string; state: RootState }
>(
  "weeklyScorecards/memberSaveWeeklyScorecards",
  async (weeklyScorecardsPayload, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    console.log(weeklyScorecardsPayload); // Ensure payload is correct

    try {
      const response = await axiosInstance.post(
        "/weekly-scorecards/save-for-member",
        weeklyScorecardsPayload, // Send the payload to the API
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response);

      return response.data; // Return the response data (updated scorecards)
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to save weekly scorecards"
      );
    }
  }
);
// Async thunk for fetching weekly scorecards
export const fetchWeeklyScorecards = createAsyncThunk<
  WeeklyScorecard[],
  void,
  { rejectValue: string; state: RootState }
>(
  "weeklyScorecards/fetchWeeklyScorecards",
  async (_, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const response = await axiosInstance.get("/weekly-scorecards", {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch weekly scorecards"
      );
    }
  }
);

export const fetchMemberWeeklyScorecards = createAsyncThunk<
  WeeklyScorecard[],
  void,
  { rejectValue: string; state: RootState }
>(
  "weeklyScorecards/fetchWeeklyScorecards",
  async (_, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const response = await axiosInstance.get(
        "/weekly-scorecards/member-scorecards",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch weekly scorecards"
      );
    }
  }
);

// Async thunk for fetching weekly scorecards with goal details
export const fetchWeeklyScorecardsWithDetails = createAsyncThunk<
  WeeklyScorecard[],
  void,
  { rejectValue: string; state: RootState }
>(
  "weeklyScorecards/fetchWeeklyScorecardsWithDetails",
  async (_, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const response = await axiosInstance.get(
        "/weekly-scorecards/with-details",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(
        error.response?.data?.message ||
          "Failed to fetch weekly scorecards with details"
      );
    }
  }
);

const weeklyScorecardSlice = createSlice({
  name: "weeklyScorecards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Weekly Scorecards
      .addCase(fetchWeeklyScorecards.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchWeeklyScorecards.fulfilled,
        (state, action: PayloadAction<WeeklyScorecard[]>) => {
          state.weeklyScorecards = action.payload || [];
          state.loading = false;
        }
      )
      .addCase(
        fetchWeeklyScorecards.rejected,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      // Fetch Weekly Scorecards with Details
      .addCase(fetchWeeklyScorecardsWithDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchWeeklyScorecardsWithDetails.fulfilled,
        (state, action: PayloadAction<WeeklyScorecard[]>) => {
          state.weeklyScorecards = action.payload || [];
          state.loading = false;
        }
      )
      .addCase(
        fetchWeeklyScorecardsWithDetails.rejected,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      // Save Weekly Scorecards
      .addCase(saveWeeklyScorecards.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        saveWeeklyScorecards.fulfilled,
        (state, action: PayloadAction<WeeklyScorecard[]>) => {
          state.weeklyScorecards = action.payload || [];
          state.loading = false;
        }
      )
      .addCase(
        saveWeeklyScorecards.rejected,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default weeklyScorecardSlice.reducer;
