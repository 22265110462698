import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "api/axios";
import { RootState } from "store/store";

export enum Priority {
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
}

export interface Member {
  id: string;
  name: string;
}

export interface Client {
  name: string;
}

export interface Issue {
  id: string;
  createdAt: Date;
  clientId?: string;
  priority: Priority;
  issue: string;
  notes: string;
  solution: string;
  forAll?: boolean;
  solved: boolean | null;
  memberIds?: string[];
  ownerIds?: string[];
  members: Member[];
  client?: Client;
  solvedAt?: Date;
  divisionId?: string;
}

interface IssueState {
  issues: Issue[];
  loading: boolean;
  error: string | null;
}

const initialState: IssueState = {
  issues: [],
  loading: false,
  error: null,
};

export const createIssueForMember = createAsyncThunk<
  Issue,
  Omit<
    Issue,
    | "id"
    | "createdAt"
    | "members"
    | "solvedAt"
    | "clientId"
    | "solvedAt"
    | "forAll"
  >, // Omit fields that are server-side created
  { rejectValue: string; state: RootState }
>(
  "issues/createIssueForMember",
  async (issue, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const response = await axiosInstance.post(
        "/issues/createForMember",
        issue,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to create Issue"
      );
    }
  }
);

export const createIssueForClient = createAsyncThunk<
  Issue,
  Omit<
    Issue,
    | "id"
    | "createdAt"
    | "members"
    | "solvedAt"
    | "clientId"
    | "solvedAt"
    | "forAll"
  >, // Omit fields that are server-side created
  { rejectValue: string; state: RootState }
>(
  "issues/createIssueForClient",
  async (issue, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    console.log(issue);
    try {
      const response = await axiosInstance.post(
        "/issues/createForClient",
        issue,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to create Issue"
      );
    }
  }
);

export const fetchIssuesForMember = createAsyncThunk<
  Issue[],
  void,
  { rejectValue: string; state: RootState }
>("issues/fetchIssuesForMember", async (_, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    const response = await axiosInstance.get("/issues/", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to fetch issues for member"
    );
  }
});

export const fetchIssuesForClient = createAsyncThunk<
  Issue[],
  void,
  { rejectValue: string; state: RootState }
>("issues/fetchIssuesForClient", async (_, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    const response = await axiosInstance.get("/issues/", {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(
      error.response?.data?.message || "Failed to fetch issues for client"
    );
  }
});

export const updateIssueForMember = createAsyncThunk<
  Issue,
  { id: string; issue: Partial<Issue> },
  { rejectValue: string; state: RootState }
>(
  "issues/updateIssueForMember",
  async ({ id, issue }, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    console.log(id);
    console.log(issue);
    try {
      const response = await axiosInstance.put(
        `/issues/updateForMember/${id}`,
        issue,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(
        error.response?.data?.message || "Failed to update Issue"
      );
    }
  }
);

export const updateIssueForClient = createAsyncThunk<
  Issue,
  { id: string; issue: Partial<Issue> },
  { rejectValue: string; state: RootState }
>(
  "issues/updateIssueForClient",
  async ({ id, issue }, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    console.log("🚀 ~ asdsadsasadsa:", issue);

    console.log("🚀 ~ id:", id);
    try {
      const response = await axiosInstance.put(
        `/issues/updateForClient/${id}`,
        issue,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to update Issue"
      );
    }
  }
);

export const deleteIssueForMember = createAsyncThunk<
  string,
  { id: string },
  { rejectValue: string; state: RootState }
>(
  "issues/deleteIssueForMember",
  async ({ id }, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      await axiosInstance.delete(`/issues/deleteForMember/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return id;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to delete Issue"
      );
    }
  }
);
export const deleteIssueForClient = createAsyncThunk<
  string,
  { id: string },
  { rejectValue: string; state: RootState }
>(
  "issues/deleteIssueForClient",
  async ({ id }, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      await axiosInstance.delete(`/issues/deleteForClient/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return id;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to delete Issue"
      );
    }
  }
);

const issueSlice = createSlice({
  name: "issues",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIssuesForMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchIssuesForMember.fulfilled,
        (state, action: PayloadAction<Issue[]>) => {
          state.issues = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        fetchIssuesForMember.rejected,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(fetchIssuesForClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchIssuesForClient.fulfilled,
        (state, action: PayloadAction<Issue[]>) => {
          state.issues = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        fetchIssuesForClient.rejected,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(createIssueForMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createIssueForMember.fulfilled,
        (state, action: PayloadAction<Issue>) => {
          // state.issues.push(action.payload);
          state.loading = false;
        }
      )
      .addCase(
        createIssueForMember.rejected,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(createIssueForClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createIssueForClient.fulfilled,
        (state, action: PayloadAction<Issue>) => {
          // state.issues.push(action.payload);
          state.loading = false;
        }
      )
      .addCase(
        createIssueForClient.rejected,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(updateIssueForMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateIssueForMember.fulfilled,
        (state, action: PayloadAction<Issue>) => {
          // const index = state.issues.findIndex(
          //   (issue) => issue.id === action.payload.id
          // );
          // if (index !== -1) {
          //   state.issues[index] = action.payload;
          // }
          state.loading = false;
        }
      )
      .addCase(
        updateIssueForMember.rejected,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(updateIssueForClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateIssueForClient.fulfilled,
        (state, action: PayloadAction<Issue>) => {
          // const index = state.issues.findIndex(
          //   (issue) => issue.id === action.payload.id
          // );
          // if (index !== -1) {
          //   state.issues[index] = action.payload;
          // }
          state.loading = false;
        }
      )
      .addCase(
        updateIssueForClient.rejected,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(deleteIssueForMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteIssueForMember.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.issues = state.issues.filter(
            (issue) => issue.id !== action.payload
          );
          state.loading = false;
        }
      )
      .addCase(
        deleteIssueForMember.rejected,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(deleteIssueForClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteIssueForClient.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.issues = state.issues.filter(
            (issue) => issue.id !== action.payload
          );
          state.loading = false;
        }
      )
      .addCase(
        deleteIssueForClient.rejected,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.error = action.payload;
        }
      );
  },
});

export default issueSlice.reducer;
