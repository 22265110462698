import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "api/axios";
import { RootState } from "store/store";
import { clearCache } from "utils/cacheUtilts";
export interface User {
  id: string;
  name: string | null;
  email: string | null;
  role?: string;
  type?: string;
}

interface AuthState {
  token: string | null;
  role: string;
  user: User | null;
  loading: boolean;
  error: string | null;
  impersonatingAdmin: boolean;
  impersonatingConsultant: boolean;
  validatingToken: boolean;
  currentSessionId: string;
  sessionId: string | null; // Add sessionId to the state
  adminSessionId?: string | null; // Add sessionId to the state
  consultantSessionId?: string | null; // Add sessionId to the state
  loginComplete: boolean;
  impersonatingConsultantId?: string;
}

const initialState: AuthState = {
  token: null,
  role: "",
  user: null,
  loading: false,
  error: null,
  impersonatingAdmin: false,
  impersonatingConsultant: false,
  currentSessionId: "",
  validatingToken: false,
  sessionId: null,
  adminSessionId: null,
  consultantSessionId: null,
  loginComplete: false,
};

// Async thunk to handle normal consultant login
export const loginConsultant = createAsyncThunk(
  "auth/loginConsultant",
  async (
    payload: { email: string; password: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        "auth/login-consultant",
        payload
      );
      const { access_token, session_id } = response.data; // Destructure session_id from response
      const decodedToken: any = JSON.parse(atob(access_token.split(".")[1]));
      const user = {
        email: decodedToken.email,
        id: decodedToken.sub,
        name: decodedToken.name,
      };
      dispatch(authSlice.actions.setLoginComplete(true)); // Set loginComplete flag
      dispatch(authSlice.actions.setAdminSessionId("")); // Set loginComplete flag
      dispatch(authSlice.actions.setAdminImpersonating(false)); // Set loginComplete flag
      dispatch(
        authSlice.actions.setConsultantImpersonatingId(decodedToken.sub)
      ); // Set loginComplete flag

      return {
        token: access_token,
        role: decodedToken.role,
        user,
        currentSessionId: session_id,
      };
    } catch (error: any) {
      console.log(error);

      return rejectWithValue(error.response?.data?.message || "Login failed");
    }
  }
);

// Async thunk to handle client login
export const loginClient = createAsyncThunk(
  "auth/loginClient",
  async (
    payload: { email: string; password: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post("auth/login-client", payload);
      // const { access_token } = response.data;
      const { access_token, session_id } = response.data; // Destructure session_id from response
      console.log(session_id);
      const decodedToken: any = JSON.parse(atob(access_token.split(".")[1]));

      const user = {
        email: decodedToken.email,
        id: decodedToken.sub,
        name: decodedToken.name,
      };
      console.log(user);
      console.log(decodedToken);
      dispatch(authSlice.actions.setLoginComplete(true)); // Set loginComplete flag

      return {
        token: access_token,
        role: decodedToken.role,
        user,
        currentSessionId: session_id,
      };
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Login failed");
    }
  }
);

// Async thunk to handle member login
export const loginMember = createAsyncThunk(
  "auth/loginMember",
  async (
    payload: { email: string; password: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post("auth/login-member", payload);
      const { access_token, session_id } = response.data; // Destructure session_id from response
      const decodedToken: any = JSON.parse(atob(access_token.split(".")[1]));
      const user = {
        email: decodedToken.email,
        id: decodedToken.sub,
        name: decodedToken.name,
      };
      dispatch(authSlice.actions.setLoginComplete(true)); // Set loginComplete flag
      dispatch(authSlice.actions.setAdminImpersonating(false)); // Set loginComplete flag
      dispatch(authSlice.actions.setConsultantImpersonating(false)); // Set loginComplete flag

      return {
        token: access_token,
        role: decodedToken.role,
        user,
        currentSessionId: session_id,
      };
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Login failed");
    }
  }
);

// Async thunk to handle admin login
export const loginAdmin = createAsyncThunk(
  "auth/loginAdmin",
  async (
    payload: { email: string; password: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post("auth/login-admin", payload);
      const { access_token, session_id } = response.data; // Destructure session_id from response
      const decodedToken: any = JSON.parse(atob(access_token.split(".")[1]));
      const user = {
        email: decodedToken.email,
        id: decodedToken.sub,
        name: decodedToken.name,
      };

      // Clear cache upon successful login
      clearCache();
      dispatch(authSlice.actions.setLoginComplete(true)); // Set loginComplete flag
      dispatch(authSlice.actions.setAdminImpersonating(false));
      dispatch(authSlice.actions.setAdminSessionId(session_id));
      dispatch(authSlice.actions.setConsultantImpersonating(false));

      return {
        token: access_token,
        role: decodedToken.role,
        user,
        currentSessionId: session_id,
      };
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Login failed");
    }
  }
);

// Async thunk to handle sysadmin logging in as consultant
export const loginAsConsultant = createAsyncThunk<
  {
    token: string;
    role: string;
    user: User;
    sessionId: string;
    currentSessionId: string;
  },
  { adminEmail: string; consultantEmail: string; adminToken: string },
  { state: RootState }
>(
  "auth/loginAsConsultant",
  async (
    payload: {
      adminEmail: string;
      consultantEmail: string;
      adminToken: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        "auth/login-as-consultant",
        {
          adminEmail: payload.adminEmail,
          consultantEmail: payload.consultantEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${payload.adminToken}`,
          },
        }
      );
      const { access_token, session_id } = response.data; // Destructure session_id from response
      const decodedToken: any = JSON.parse(atob(access_token.split(".")[1]));
      const user = {
        email: decodedToken.email,
        id: decodedToken.sub,
        name: decodedToken.name,
      };
      // Clear cache upon successful login
      clearCache();
      console.log(session_id);
      console.log(decodedToken.sessionId);
      console.log(decodedToken.sub);

      dispatch(
        authSlice.actions.setConsultantImpersonatingId(decodedToken.sub)
      );
      dispatch(authSlice.actions.setAdminImpersonating(true));
      dispatch(authSlice.actions.setConsultantImpersonating(false));
      dispatch(authSlice.actions.setSessionId(decodedToken.sessionId));

      dispatch(authSlice.actions.setLoginComplete(true));
      return {
        token: access_token,
        role: decodedToken.role,
        user,
        sessionId: decodedToken.sessionId,
        currentSessionId: session_id,
      };
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Login failed");
    }
  }
);

// Async thunk to handle logout as consultant and restore admin session
export const logoutAsConsultant = createAsyncThunk(
  "auth/logoutAsConsultant",
  async (
    payload: {
      consultantId: string;
      sessionId: string;
      currentSessionId: string;
      adminSessionId: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      console.log(payload);
      const response = await axiosInstance.post(
        "auth/logout-as-consultant",
        payload
      );
      if (response.data.access_token) {
        const access_token = response.data.access_token;
        const decodedToken: any = JSON.parse(atob(access_token.split(".")[1]));
        const user = {
          email: decodedToken.email,
          id: decodedToken.sub,
          name: decodedToken.name,
        };
        dispatch(
          authSlice.actions.restoreAdminSession({
            token: access_token,
            role: decodedToken.role,
            user,
          })
        );
        return { token: access_token, role: decodedToken.role, user };
      } else {
        dispatch(authSlice.actions.logoutSuccess());
        return null;
      }
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.response?.data?.message || "Logout failed");
    }
  }
);

// ! Client

// Async thunk to handle sysadmin logging in as consultant
export const loginAsClient = createAsyncThunk(
  "auth/loginAsClient",
  async (
    payload: {
      adminEmail: string;
      clientEmail: string;
      adminToken: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        "auth/login-as-client",
        {
          adminEmail: payload.adminEmail,
          clientEmail: payload.clientEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${payload.adminToken}`,
          },
        }
      );
      const { access_token, session_id } = response.data; // Destructure session_id from response

      const decodedToken: any = JSON.parse(atob(access_token.split(".")[1]));
      const user = {
        email: decodedToken.email,
        id: decodedToken.sub,
        name: decodedToken.name,
      };
      // Clear cache upon successful login
      clearCache();
      console.log(session_id);
      console.log(decodedToken.sessionId);
      console.log(decodedToken.sub);

      dispatch(authSlice.actions.setAdminImpersonating(true));
      dispatch(authSlice.actions.setConsultantImpersonating(false));

      dispatch(authSlice.actions.setSessionId(decodedToken.sessionId)); // Store sessionId
      return {
        token: access_token,
        role: decodedToken.role,
        user,
        sessionId: decodedToken.sessionId,
        currentSessionId: session_id,
      };
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Login failed");
    }
  }
);

// Async thunk to handle logout as consultant and restore admin session
export const logoutAsClient = createAsyncThunk(
  "auth/logoutAsClient",
  async (
    payload: {
      clientId: string;
      sessionId: string;
      currentSessionId: string;
      adminSessionId: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      console.log(payload);
      const response = await axiosInstance.post(
        "auth/logout-as-client",
        payload
      );
      if (response.data.access_token) {
        const access_token = response.data.access_token;
        const decodedToken: any = JSON.parse(atob(access_token.split(".")[1]));
        const user = {
          email: decodedToken.email,
          id: decodedToken.sub,
          name: decodedToken.name,
        };
        dispatch(
          authSlice.actions.restoreAdminSession({
            token: access_token,
            role: decodedToken.role,
            user,
          })
        );
        return { token: access_token, role: decodedToken.role, user };
      } else {
        dispatch(authSlice.actions.logoutSuccess());
        return null;
      }
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.response?.data?.message || "Logout failed");
    }
  }
);
// ! Member

// Async thunk to handle sysadmin logging in as consultant
export const loginAsMember = createAsyncThunk(
  "auth/loginAsMember",
  async (
    payload: {
      adminEmail: string;
      memberEmail: string;
      adminToken: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        "auth/login-as-member",
        {
          adminEmail: payload.adminEmail,
          memberEmail: payload.memberEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${payload.adminToken}`,
          },
        }
      );
      const { access_token, session_id } = response.data;
      console.log(session_id);
      const decodedToken: any = JSON.parse(atob(access_token.split(".")[1]));
      const user = {
        email: decodedToken.email,
        id: decodedToken.sub,
        name: decodedToken.name,
      };
      console.log(decodedToken.sub);
      console.log(decodedToken.sessionId);
      console.log(decodedToken);
      dispatch(authSlice.actions.setAdminImpersonating(true));
      dispatch(authSlice.actions.setConsultantImpersonating(false));

      dispatch(authSlice.actions.setSessionId(decodedToken.sessionId)); // Store sessionId
      dispatch(authSlice.actions.setLoginComplete(true)); // Set loginComplete flag

      return {
        token: access_token,
        role: decodedToken.role,
        user,
        sessionId: decodedToken.sessionId,
        currentSessionId: session_id,
      };
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Login failed");
    }
  }
);

// Async thunk to handle logout as consultant and restore admin session
export const logoutAsMember = createAsyncThunk(
  "auth/logoutAsMember",
  async (
    payload: {
      memberId: string;
      sessionId: string;
      currentSessionId: string;
      adminSessionId: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      console.log(payload);
      const response = await axiosInstance.post(
        "auth/logout-as-member",
        payload
      );
      if (response.data.access_token) {
        const access_token = response.data.access_token;
        const decodedToken: any = JSON.parse(atob(access_token.split(".")[1]));
        const user = {
          email: decodedToken.email,
          id: decodedToken.sub,
          name: decodedToken.name,
        };
        dispatch(
          authSlice.actions.restoreAdminSession({
            token: access_token,
            role: decodedToken.role,
            user,
          })
        );
        return { token: access_token, role: decodedToken.role, user };
      } else {
        dispatch(authSlice.actions.logoutSuccess());
        return null;
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Logout failed");
    }
  }
);

// Async thunk to handle consultant logging in as client
export const consultantLoginAsClient = createAsyncThunk(
  "auth/consultantLoginAsClient",
  async (
    payload: {
      consultantEmail: string;
      clientEmail: string;
      consultantToken: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      console.log("Logging in consultant as client with payload:", payload);
      const response = await axiosInstance.post(
        "auth/consultant-login-as-client",
        {
          consultantEmail: payload.consultantEmail,
          clientEmail: payload.clientEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${payload.consultantToken}`,
          },
        }
      );
      const { access_token, session_id } = response.data;
      console.log(session_id);
      const decodedToken: any = JSON.parse(atob(access_token.split(".")[1]));
      const user = {
        email: decodedToken.email,
        id: decodedToken.sub,
        name: decodedToken.name,
      };
      console.log(decodedToken.sessionId);
      console.log(decodedToken.sub);

      console.log("Received token and session ID:", access_token, session_id);
      dispatch(authSlice.actions.setAdminImpersonating(false));
      dispatch(authSlice.actions.setConsultantImpersonating(true));
      dispatch(authSlice.actions.setSessionId(decodedToken.sessionId));
      dispatch(authSlice.actions.setLoginComplete(true));
      return {
        token: access_token,
        role: decodedToken.role,
        user,
        sessionId: decodedToken.sessionId,
        currentSessionId: session_id,
      };
    } catch (error: any) {
      console.error("Login failed:", error);
      return rejectWithValue(error.response?.data?.message || "Login failed");
    }
  }
);

// Async thunk to handle consultant logging in as client from admin
export const consultantLoginAsClientFromAdmin = createAsyncThunk(
  "auth/consultantLoginAsClientFromAdmin",
  async (
    payload: {
      consultantEmail: string;
      clientEmail: string;
      consultantToken: string;
      adminSessionId: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      console.log(payload);
      const response = await axiosInstance.post(
        "auth/consultant-login-as-client",
        {
          consultantEmail: payload.consultantEmail,
          clientEmail: payload.clientEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${payload.consultantToken}`,
            "Admin-Session-Id": payload.adminSessionId, // Include the admin session ID in the header
          },
        }
      );
      const { access_token, session_id } = response.data;

      console.log(access_token);
      console.log(session_id);
      const decodedToken: any = JSON.parse(atob(access_token.split(".")[1]));
      const user = {
        email: decodedToken.email,
        id: decodedToken.sub,
        name: decodedToken.name,
      };

      dispatch(authSlice.actions.setAdminImpersonating(true));
      dispatch(authSlice.actions.setConsultantImpersonating(true));

      dispatch(authSlice.actions.setSessionId(decodedToken.sessionId));
      dispatch(authSlice.actions.setLoginComplete(true));
      return {
        token: access_token,
        role: decodedToken.role,
        user,
        sessionId: decodedToken.sessionId,
        currentSessionId: session_id,
      };
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Login failed");
    }
  }
);

// Async thunk to handle client logout and restore consultant session
export const logoutClientAndRestoreConsultant = createAsyncThunk<
  {
    token: string;
    role: string;
    user: User;
  } | null,
  { clientId: string; sessionId: string; consultantSessionId: string },
  { state: RootState }
>(
  "auth/logoutClientAndRestoreConsultant",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      console.log(payload);
      const response = await axiosInstance.post(
        "auth/consultant-logout-as-consultant",
        payload
      );
      if (response.data.access_token) {
        const access_token = response.data.access_token;
        const decodedToken: any = JSON.parse(atob(access_token.split(".")[1]));
        const user = {
          email: decodedToken.email,
          id: decodedToken.sub,
          name: decodedToken.name,
        };
        console.log(user);
        console.log(access_token);
        console.log(decodedToken);
        dispatch(
          authSlice.actions.restoreConsultantSession({
            token: access_token,
            role: decodedToken.role,
            user,
          })
        );
        return { token: access_token, role: decodedToken.role, user };
      } else {
        dispatch(authSlice.actions.logoutSuccess());
        return null;
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Logout failed");
    }
  }
);

// ! CLIENT (ADMIN) LOGOUT
export const logoutClientAndRestoreConsultantForAdmin = createAsyncThunk<
  {
    token: string;
    role: string;
    user: User;
  } | null,
  {
    clientId: string;
    sessionId: string;
    consultantSessionId: string;
    adminSessionId: string;
  },
  { state: RootState }
>(
  "auth/logoutClientAndRestoreConsultant",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      console.log(payload);
      const response = await axiosInstance.post(
        "auth/consultant-logout-as-consultant-for-admin",
        payload
      );
      if (response.data.access_token) {
        const access_token = response.data.access_token;
        const decodedToken: any = JSON.parse(atob(access_token.split(".")[1]));
        const user = {
          email: decodedToken.email,
          id: decodedToken.sub,
          name: decodedToken.name,
        };
        console.log(user);
        console.log(access_token);
        console.log(decodedToken);
        dispatch(
          authSlice.actions.restoreConsultantSession({
            token: access_token,
            role: decodedToken.role,
            user,
          })
        );
        return { token: access_token, role: decodedToken.role, user };
      } else {
        dispatch(authSlice.actions.logoutSuccess());
        return null;
      }
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Logout failed");
    }
  }
);

// Adjust the logout thunk to handle impersonating flag
export const logout = createAsyncThunk<
  void,
  { role: string },
  { state: RootState }
>("auth/logout", async ({ role }, { getState, dispatch, rejectWithValue }) => {
  try {
    console.log("logout");
    const state = getState() as RootState;
    const userId = state.auth.user?.id;
    const token = state.auth.token;
    const impersonatingAdmin = state.auth.impersonatingAdmin;
    const impersonatingConsultant = state.auth.impersonatingConsultant;
    const sessionId = state.auth.sessionId;
    const adminSessionId = state.auth.adminSessionId;
    const consultantSessionId = state.auth.consultantSessionId;
    const currentSessionId = state.auth.currentSessionId;

    console.log(currentSessionId);
    if (!userId) {
      throw new Error("User ID not found");
    }

    console.log(impersonatingAdmin);
    console.log(impersonatingConsultant);
    console.log(sessionId);
    console.log(adminSessionId);
    console.log(role);

    if (impersonatingAdmin && !impersonatingConsultant) {
      if (role === "consultant") {
        console.log("run");
        await dispatch(
          logoutAsConsultant({
            consultantId: userId,
            sessionId: adminSessionId || "",
            currentSessionId: currentSessionId,
            adminSessionId: adminSessionId || "",
          })
        ).unwrap();
      } else if (role === "client") {
        console.log(userId);
        console.log(sessionId);
        await dispatch(
          logoutAsClient({
            clientId: userId,
            sessionId: sessionId || "",
            currentSessionId: currentSessionId,

            adminSessionId: adminSessionId || "",
          })
        ).unwrap();
      } else if (role === "member") {
        console.log("ran");
        await dispatch(
          logoutAsMember({
            memberId: userId,
            sessionId: sessionId || "",
            currentSessionId: currentSessionId,
            adminSessionId: adminSessionId || "",
          })
        ).unwrap();
      }
    } else if (impersonatingConsultant) {
      console.log("ran");
      if (!adminSessionId || adminSessionId.trim().length === 0) {
        console.log("RUN");
        await dispatch(
          logoutClientAndRestoreConsultant({
            clientId: userId,
            sessionId: sessionId || "",
            consultantSessionId: consultantSessionId || "",
          })
        ).unwrap();
      } else {
        console.log("RUN");
        console.log(adminSessionId);

        await dispatch(
          logoutClientAndRestoreConsultantForAdmin({
            clientId: userId,
            sessionId: sessionId || "",
            consultantSessionId: consultantSessionId || "",
            adminSessionId: adminSessionId,
          })
        ).unwrap();
      }
    } else {
      console.log("ran");
      console.log(sessionId);
      console.log(currentSessionId);
      await axiosInstance.post(
        "auth/logout",
        { userId, currentSessionId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(authSlice.actions.logoutSuccess());
    }
    // Clear localStorage and sessionStorage
    localStorage.clear();
    sessionStorage.clear();
  } catch (error) {
    console.log(error);
    if (error instanceof Error) {
      // If error has a response property, it's likely an Axios error
      if ((error as any).response?.data?.message) {
        return rejectWithValue((error as any).response.data.message as string);
      }
      return rejectWithValue(error.message);
    }
    return rejectWithValue("Logout failed");
  }
});

export const validateToken = createAsyncThunk(
  "auth/validateToken",
  async (
    { userId, token }: { userId: string; token: string },
    { rejectWithValue }
  ) => {
    try {
      console.log(userId);
      console.log(token);
      const response = await axiosInstance.post(
        `auth/validate-token`,
        { userId, token },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      setLoginComplete(true);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("Error data:", error.response.data);
        console.log("Error status:", error.response.status);
        console.log("Error headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log("Error request:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error message:", error.message);
      }
      console.log("Error config:", error.config);
      return rejectWithValue(
        error.response?.data?.message || "Token validation failed"
      );
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Clear token and reset auth state
    clearToken(state) {
      state.token = null;
      state.role = "";
      state.user = null;
      state.impersonatingAdmin = false;
      state.impersonatingConsultant = false;
      state.currentSessionId = "";
      state.validatingToken = false;
      state.sessionId = null;
      state.adminSessionId = null;
      state.consultantSessionId = null;
      state.loginComplete = false;
      state.impersonatingConsultantId = undefined;
    },
    logoutSuccess(state) {
      state.token = null;
      state.role = "";
      state.user = null;
      state.impersonatingAdmin = false;
      state.impersonatingConsultant = false;
      state.currentSessionId = "";
      state.validatingToken = false;
      state.sessionId = null;
    },
    setToken(state, action: PayloadAction<string | null>) {
      state.token = action.payload;
    },
    setAdminImpersonating(state, action: PayloadAction<boolean>) {
      state.impersonatingAdmin = action.payload;
    },
    setConsultantImpersonating(state, action: PayloadAction<boolean>) {
      state.impersonatingConsultant = action.payload;
    },
    setConsultantImpersonatingId(state, action: PayloadAction<string>) {
      state.impersonatingConsultantId = action.payload;
    },

    setSessionId(state, action: PayloadAction<string>) {
      state.sessionId = action.payload;
    },
    setAdminSessionId(state, action: PayloadAction<string>) {
      state.adminSessionId = action.payload;
    },
    setLoginComplete: (state, action: PayloadAction<boolean>) => {
      state.loginComplete = action.payload;
    },
    restoreAdminSession(
      state,
      action: PayloadAction<{ token: string; role: string; user: User }>
    ) {
      state.token = action.payload.token;
      state.role = action.payload.role;
      state.user = action.payload.user;
      state.impersonatingAdmin = false;
      state.sessionId = null;
    },
    restoreConsultantSession(
      state,
      action: PayloadAction<{ token: string; role: string; user: User }>
    ) {
      state.token = action.payload.token;
      state.role = action.payload.role;
      state.user = action.payload.user;
      state.impersonatingConsultant = false;
      state.sessionId = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(consultantLoginAsClientFromAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        consultantLoginAsClientFromAdmin.fulfilled,
        (
          state,
          action: PayloadAction<{
            token: string;
            role: string;
            user: User;
            sessionId: string;
          }>
        ) => {
          state.token = action.payload.token;
          state.role = action.payload.role;
          state.user = action.payload.user;
          state.loading = false;
          state.loginComplete = true;
          state.sessionId = action.payload.sessionId;
        }
      )
      .addCase(
        consultantLoginAsClientFromAdmin.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(consultantLoginAsClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        consultantLoginAsClient.fulfilled,
        (
          state,
          action: PayloadAction<{
            token: string;
            role: string;
            user: User;
            sessionId: string;
            currentSessionId: string;
          }>
        ) => {
          state.token = action.payload.token;
          state.role = action.payload.role;
          state.user = action.payload.user;
          state.loading = false;
          state.loginComplete = true;
          state.sessionId = action.payload.sessionId;
          state.currentSessionId = action.payload.currentSessionId;
        }
      )
      .addCase(
        consultantLoginAsClient.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(logoutClientAndRestoreConsultant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        logoutClientAndRestoreConsultant.fulfilled,
        (
          state,
          action: PayloadAction<{
            token: string;
            role: string;
            user: User;
          } | null>
        ) => {
          if (action.payload) {
            state.token = action.payload.token;
            state.role = action.payload.role;
            state.user = action.payload.user;
            state.impersonatingConsultant = false;
          } else {
            state.token = null;
            state.role = "";
            state.user = null;
            state.impersonatingConsultant = false;
          }
          state.loading = false;
        }
      )
      .addCase(
        logoutClientAndRestoreConsultant.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(loginAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        loginAdmin.fulfilled,
        (
          state,
          action: PayloadAction<{
            token: string;
            role: string;
            user: User;
            currentSessionId: string;
          }>
        ) => {
          state.token = action.payload.token;
          state.role = action.payload.role;
          state.user = action.payload.user;
          state.loading = false;
          state.adminSessionId = action.payload.currentSessionId;
        }
      )
      .addCase(loginAdmin.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(loginAsConsultant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        loginAsConsultant.fulfilled,
        (
          state,
          action: PayloadAction<{
            token: string;
            role: string;
            user: User;
            sessionId: string;
            currentSessionId: string;
          }>
        ) => {
          state.token = action.payload.token;
          state.role = action.payload.role;
          state.user = action.payload.user;
          state.sessionId = action.payload.sessionId;
          state.currentSessionId = action.payload.currentSessionId;

          state.loading = false;
        }
      )
      .addCase(
        loginAsConsultant.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(logoutAsConsultant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        logoutAsConsultant.fulfilled,
        (
          state,
          action: PayloadAction<{
            token: string;
            role: string;
            user: User;
          } | null>
        ) => {
          if (action.payload) {
            state.token = action.payload.token;
            state.role = action.payload.role;
            state.user = action.payload.user;
            state.impersonatingAdmin = false;
          } else {
            state.token = null;
            state.role = "";
            state.user = null;
            state.impersonatingAdmin = false;
          }
          state.loading = false;
        }
      )
      .addCase(
        logoutAsConsultant.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(logout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(logout.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(logoutAsClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        logoutAsClient.fulfilled,
        (
          state,
          action: PayloadAction<{
            token: string;
            role: string;
            user: User;
          } | null>
        ) => {
          if (action.payload) {
            state.token = action.payload.token;
            state.role = action.payload.role;
            state.user = action.payload.user;
            state.impersonatingConsultant = false;
          } else {
            state.token = null;
            state.role = "";
            state.user = null;
            state.impersonatingConsultant = false;
          }
          state.loading = false;
        }
      )
      .addCase(logoutAsClient.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(loginAsClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        loginAsClient.fulfilled,
        (
          state,
          action: PayloadAction<{
            token: string;
            role: string;
            user: User;
            sessionId: string;
          }>
        ) => {
          state.token = action.payload.token;
          state.role = action.payload.role;
          state.user = action.payload.user;
          state.loading = false;
          state.loginComplete = true;
          state.sessionId = action.payload.sessionId;
        }
      )
      .addCase(loginAsClient.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(loginAsMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        loginAsMember.fulfilled,
        (
          state,
          action: PayloadAction<{
            token: string;
            role: string;
            user: User;
            sessionId: string;
            currentSessionId: string;
          }>
        ) => {
          state.token = action.payload.token;
          state.role = action.payload.role;
          state.user = action.payload.user;
          state.loading = false;
          state.sessionId = action.payload.sessionId;
          state.currentSessionId = action.payload.currentSessionId;
        }
      )
      .addCase(loginAsMember.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(logoutAsMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        logoutAsMember.fulfilled,
        (
          state,
          action: PayloadAction<{
            token: string;
            role: string;
            user: User;
          } | null>
        ) => {
          if (action.payload) {
            state.token = action.payload.token;
            state.role = action.payload.role;
            state.user = action.payload.user;
            state.impersonatingAdmin = false;
          } else {
            state.token = null;
            state.role = "";
            state.user = null;
            state.impersonatingAdmin = false;
          }
          state.loading = false;
        }
      )
      .addCase(logoutAsMember.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(validateToken.pending, (state) => {
        state.validatingToken = true;
      })
      .addCase(validateToken.fulfilled, (state) => {
        state.validatingToken = false;
        state.loginComplete = true;
      })
      .addCase(validateToken.rejected, (state) => {
        state.validatingToken = false;
        state.token = null;
        state.role = "";
        state.user = null;
        state.loginComplete = false;
      })
      .addCase(loginConsultant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        loginConsultant.fulfilled,
        (
          state,
          action: PayloadAction<{
            token: string;
            role: string;
            user: User;
            currentSessionId: string;
          }>
        ) => {
          state.token = action.payload.token;
          state.role = action.payload.role;
          state.user = action.payload.user;
          state.sessionId = action.payload.currentSessionId;
          state.currentSessionId = action.payload.currentSessionId;
          state.loading = false;
          state.consultantSessionId = action.payload.currentSessionId;
        }
      )
      .addCase(
        loginConsultant.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        }
      )
      .addCase(loginClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        loginClient.fulfilled,
        (
          state,
          action: PayloadAction<{
            token: string;
            role: string;
            user: User;
            currentSessionId: string;
          }>
        ) => {
          state.token = action.payload.token;
          state.role = action.payload.role;
          state.user = action.payload.user;
          state.loading = false;
          state.currentSessionId = action.payload.currentSessionId;
        }
      )
      .addCase(loginClient.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(loginMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        loginMember.fulfilled,
        (
          state,
          action: PayloadAction<{
            token: string;
            role: string;
            user: User;
            currentSessionId: string;
          }>
        ) => {
          state.token = action.payload.token;
          state.role = action.payload.role;
          state.user = action.payload.user;
          state.loading = false;
          state.currentSessionId = action.payload.currentSessionId;
        }
      )
      .addCase(loginMember.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  logoutSuccess,
  setToken,
  setAdminImpersonating,
  setConsultantImpersonating,
  setLoginComplete,
  setSessionId,
  restoreAdminSession,
  clearToken,
} = authSlice.actions;

export default authSlice.reducer;
