import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "api/axios";
import { RootState } from "store/store";
import { Task } from "types/task";
import { Goal } from "./goalSlice";
import { Client, Issue } from "./issueSlice";
import { Rock } from "./rockSlice";
import { Department } from "./departmentSlice";
export interface Note {
  id: string;
  clientId: string;
  title: string;
  description: string;
  tags?: string[];
  highImportance?: boolean;
  isPinned?: boolean;
  createdAt: string;
}
export interface SessionDetail {
  id: string;
  segueTime: number;
  updatesTime: number;
  scorecardTime: number;
  goalsTime: number;
  tasksTime: number;
  issuesTime: number;
  summaryTime: number;
  rating: number;
  createdAt: string;
}

export interface TeamValueRecord {
  id: string;
  rating: number;
  value: string;
  createdAt: string;
}
// Define WeeklyScorecard and Scorecard interfaces
export interface WeeklyScorecard {
  id: string;
  week: string; // Week as a string, like 'W40'
  scorecards: Scorecard[]; // List of scorecards for the week
}

export interface Scorecard {
  id: string;
  goalId: string;
  value: string; // Value for the scorecard
  goal: Goal; // Goal associated with this scorecard
}

// Goal interface (assuming it's already defined)
export interface Goal {
  id: string;
  name: string;
  measurable: string;
  measurableValue: string[];
  departmentId: string;
  department: Department;
  clientId: string;
  client: Client;
  memberId?: string;
  member?: Member;
}

export interface Division {
  name: string;
  id: string;
}
export interface ClientDetails {
  id: string;
  businessName: string;
  name: string;
  email: string;
  timezone: string;
  type?: string; // Client type(s)
  logo?: string;
  values?: string[]; // Client values
  teamValueRecords?: TeamValueRecord[]; // Client values
  createdAt: string;
  sessionDetails?: SessionDetail[]; // Include session details
  notes?: Note[]; // Include notes
  profilePicture?: string; // Optional profile picture URL
  description?: string; // Optional description
  experience?: string[]; // List of experience points
  achievements?: string[]; // List of achievements
  linkedIn?: string; // Optional LinkedIn profile
  personalWebsite?: string; // Optional personal website
  divisions: Division[];
  // Newly added fields:
  tasks?: Task[]; // List of tasks related to the client
  rocks?: Rock[]; // List of goals related to the client
  issues?: Issue[]; // List of issues related to the client
  assignedMembers?: Member[]; // List of members related to the client
  weeklyScorecards?: WeeklyScorecard[];
}

export interface Member {
  id: string;
  name: string;
  email: string;
  timezone: string;
  role: string;
  type: string;
  divisions: Division[];
  departments: Department[];
}
export interface ClientConsultant {
  consultant: any;
  client: ClientDetails;
  notes?: Note[]; // Include notes
}

export interface UserDetails {
  timezone: string;
  clientConsultants?: ClientConsultant[];
  clientId?: string;
  values?: string[];
  divisions: Division[];

  // consultantId?: string;
}

export interface UserDetailsState {
  userDetails: UserDetails | null;
  loading: boolean;
  error: string | null;
}

export const initialState: UserDetailsState = {
  userDetails: null,
  loading: false,
  error: null,
};

export const fetchUserDetails = createAsyncThunk<
  UserDetails,
  void,
  { rejectValue: string; state: RootState }
>("user/fetchUserDetails", async (_, { getState, rejectWithValue }) => {
  const { token } = getState().auth;
  try {
    const response = await axiosInstance.get("/user/details", {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log(response);
    return response.data;
  } catch (error: any) {
    console.log(error);
    return rejectWithValue(
      error.response?.data?.message || "Failed to fetch user details"
    );
  }
});

const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.userDetails = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        if (action.payload) {
          // Check if action has a payload
          state.error = action.payload;
        } else {
          // Fallback if payload is undefined (optional)
          state.error = "Failed to fetch user details";
        }
        state.loading = false;
      });
  },
});

export default userDetailsSlice.reducer;
